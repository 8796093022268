export function getDuration(start ,end, duration ){
  let diff = Math.abs(duration)
  console.log("diff",diff)
   if(diff){
       if(end== null){
            end = new Date().getTime()
           }
        diff = (end - start)/1000;
        diff = Math.abs(diff);
   }
    if (diff < 60) {
      return  diff.toFixed() + ' sec';
    }
    else if (diff >= 60 && diff < 3600) {
      let minute = Math.floor(diff / 60);
      return  minute + ' min';
    }
    else {
      let minute = Math.floor(diff/60);
      let hour = Math.floor(minute/60);
      minute = Math.floor(minute - hour*60);
      return  hour + ' hr ' + minute + ' min' ;
    }
  }
import { environment } from '../../environments/environment';

export const BASE_URL: string = environment.baseUrl;
export const COOKIE_URL: string = environment.cookieURL

export const LOGIN: string = `${BASE_URL}/auth/login`;
export const LOGOUT: string = `${BASE_URL}/auth/logout`;
export const CHECK_TOKEN: string = `${BASE_URL}/auth/check/token`;
export const GET_USER_ROLE: string = `${BASE_URL}/api/user/permission/`;
export const GET_WALLET_BALANCE: string =`${BASE_URL}/api/wallet/fleet/balance/`;
export const DOWNLOAD_CSV: string =`${BASE_URL}/api/fleet/download/csv/`;
export const DOWNLOAD_RECEIPT_FOR_ORDER_ID: string =`${BASE_URL}/api/v1/receipt/download/pdf?orderId=`

export const GET_CHARGING_STATION: string= `${BASE_URL}/public/api/charge/stations`;

export const GET_TRANSACTION: string =`${BASE_URL}/api/fleet/`;
export const GET_UPCOMING_RESERVATION: string =`${BASE_URL}/api/reservations/all/future/reservation`;
export const GET_LIVE_TRANSACTION: string =`${BASE_URL}/api/transaction/all/future/transactions`;
export const GET_COMPLETED_TRANSACTION: string =`${BASE_URL}/api/transaction/fleet/completed`;
export const CANCEL_RESERVATION: string =`${BASE_URL}/api/reservations/`;
export const STOP_TRANSACTION: string = `${BASE_URL}/api/transaction/RemoteStopTransaction/`;

export const GET_DRIVER_DATA: string =`${BASE_URL}/api/user/fleet/drivers/`;
export const UPDATE_DRIVER_DATA: string =`${BASE_URL}/api/user/update`;
export const ADD_DRIVER: string = `${BASE_URL}/api/user/add`;
export const DELETE_DRIVER: string =`${BASE_URL}/api/user/delete/`;

export const GET_VEHICLE_DATA: string =`${BASE_URL}/api/vehicle/details/`;
export const UPDATE_VEHICLE_DATA: string =`${BASE_URL}/api/vehicle/update`;
export const ADD_VEHICLE: string = `${BASE_URL}/api/vehicle/fleet/add`;
export const DELETE_VEHICLE: string =`${BASE_URL}/api/vehicle/fleet/delete/`;
export const GET_VEHICLE_MODEL_LIST: string =`${BASE_URL}/api/vehicle/fleet/drop-down/lists`;

export const GET_FAQ_LIST: string =`${BASE_URL}/api/fleet/faq/list`;

export const GET_PAYMENT_LIST: string= `${BASE_URL}/api/wallet/fleet/transactions/`;
export const ADD_AMOUNT: string =`${BASE_URL}/api/payments/fleet/wallet/add/amount`;
export const RAZORPAY_INITIATE: string =`${BASE_URL}/api/payments/initiate/razorpay/`;
export const CHECK_PAYMENT_STATUS: string =`${BASE_URL}/api/payments/check/order/status/`;
import { Component, OnInit, ElementRef, HostListener, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { Subscription } from 'rxjs';
import { AppService } from 'src/app/services/app.service';
import { COOKIE_URL } from 'src/app/config';
import { PermissionForUsers } from '../../guards/checkRoles';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit {
  @Output() margin: EventEmitter<number> =   new EventEmitter();
  collapse: boolean = true;
  walletBalance:any;
  allowWallet:boolean = false;
  userNameSubs: Subscription;
  walletBalanceSubs: Subscription;

  toggleCollapse() {
    // this.show = !this.show
    this.collapse = !this.collapse;
  }

  constructor(private _router: Router,private el: ElementRef,public _appService :AppService,private _cookieService: CookieService) { 
  }
  name
  headerImgURL:any;
  // checkPermission(permissionString: String) {
  //   return PermissionForUsers.checkPermission(permissionString);
  // }
  @HostListener('document:click', ['$event'])
  clickout(event) {
      if(this.el.nativeElement.contains(event.target)) {
        //clicked inside
      } else {
       
          this.collapse = false;
          this.margin.emit(201);   
        
        //clicked outside
      }
  }


  ngOnInit() {
    console.log("HEADER")
    this.headerImgURL= localStorage.getItem("headerImg");
    //this.name = localStorage.getItem('name');
    this.walletBalanceSubs = this._appService.walletBalanceMessage.subscribe(message=>{      
      this.walletBalance =Number(message);
    })
    this.userNameSubs = this._appService.userName.subscribe(message=>{      
      this.name = message;
    })
  }

  closeNav() {
    this.collapse = true;
    this.margin.emit(60);
  }

  openNav() {
    if(this.collapse==false){
      this.margin.emit(60);
    }else{
      this.margin.emit(201);
    }
    this.collapse = !this.collapse;
    
  }

  logout() {
    this.closeNav()
    this._appService.logout().subscribe(
      (response) => {
        console.log(response)
        this._cookieService.delete('accessToken', '/', COOKIE_URL);
        this._appService.isValid = false;
        this._router.navigateByUrl('/login');
      },
      (err) => {
        //console.log(err);
      }
    )
  }
  checkPermission(permissionString: String) {
    return PermissionForUsers.checkPermission(permissionString);
  }

}

<div class="container">
   
    <div class="header">
           <div style="text-align: center;">
            <div style="width: 225px; height: 40px;"></div>
             <!-- <input inputmode="text" style="width: 400px;" placeholder="&nbsp;&nbsp; Search"> -->
           </div>
            <div style="text-align: center;" class="tabs">
               <div class="tab" routerLinkActive="selectedTab" routerLink="live"> Live</div>
               <div class="tab" routerLinkActive="selectedTab" routerLink="completed"> Completed</div>
               <div class="tab" routerLinkActive="selectedTab" routerLink="upcoming"> Upcoming</div> 
           </div>
           <div style="text-align: center;">
                <p-calendar *ngIf="showCalendar" class ="dateSelector" appendTo="body" [(ngModel)]="selectedDates" [showIcon]="true" selectionMode="range"></p-calendar>
                <button *ngIf="showCalendar"  class="btn btn-primary" (click)="search()"><i class="fas fa-search"></i></button>
           </div>
           <div style="text-align: center;">
              <button class="btn btn-basic"  *ngIf="showCalendar"  (click)="downloadCSV()"> Export&nbsp;&nbsp; <i class="fas fa-download"></i></button>
           </div>
    </div>
    <router-outlet (activate)="onActivate($event)"></router-outlet>
</div>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule } from '@angular/forms';
import { DialogModule } from 'primeng/dialog';
import { TableModule } from 'primeng/table';
import { PaymentRoutingModule } from './payment-routing.module';
import { PaymentComponent } from './payment/payment.component';
import { CalendarModule } from 'primeng/calendar';
import { NgxSpinnerModule } from "ngx-spinner";

@NgModule({
  declarations: [PaymentComponent],
  imports: [
    CommonModule,
    PaymentRoutingModule,
    DropdownModule,
    FormsModule,
    DialogModule,
    TableModule,
    CalendarModule,
    NgxSpinnerModule
  ]
})
export class PaymentModule { }

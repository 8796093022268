<div class="table-container"> <!--infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" (scrolled)="onScroll()" [scrollWindow]="false"-->
  <p-table #dt [columns]="cols" [value]="transactionData" [paginator]="true" [rows]="6" totalRecords="10000" >
    <ng-template pTemplate="caption">
      <div style="display: flex; position: absolute; margin-top: -75px;">
        <input  class="form-control" type="text" style="width: 250px;" pInputText size="50" placeholder="Search"
        (input)="dt.filterGlobal($event.target.value, 'contains')" lazy="false" />
    </div>
    </ng-template>
    <ng-template pTemplate="header" let-columns>
      <tr>
        <ng-container *ngFor="let col of columns">
          <th *ngIf="col.isVisible" [ngStyle]="{ width: col.width }">
            <ng-container [ngSwitch]="col.field">
              <ng-container *ngSwitchCase="'transactionId'">
                <span [pSortableColumn]="col.field" >
                  {{ col.header }}
                  <p-sortIcon [field]="col.field" ariaLabel="Activate to sort"
                    ariaLabelDesc="Activate to sort in descending order"
                    ariaLabelAsc="Activate to sort in ascending order"></p-sortIcon>
                </span>
              </ng-container>
              <ng-container *ngSwitchCase="'stationName'">
                <span [pSortableColumn]="col.field" >
                  {{ col.header }}
                  <p-sortIcon [field]="col.field" ariaLabel="Activate to sort"
                    ariaLabelDesc="Activate to sort in descending order"
                    ariaLabelAsc="Activate to sort in ascending order"></p-sortIcon>
                </span>
              </ng-container>
              <ng-container *ngSwitchCase="'chargerName'">
                <span [pSortableColumn]="col.field" >
                  {{ col.header }}
                  <p-sortIcon [field]="col.field" ariaLabel="Activate to sort"
                    ariaLabelDesc="Activate to sort in descending order"
                    ariaLabelAsc="Activate to sort in ascending order"></p-sortIcon>
                </span>
              </ng-container>
              <ng-container *ngSwitchCase="'initialSoc'">
                <span [pSortableColumn]="col.field" >
                  {{ col.header }}
                  <p-sortIcon [field]="col.field" ariaLabel="Activate to sort"
                    ariaLabelDesc="Activate to sort in descending order"
                    ariaLabelAsc="Activate to sort in ascending order"></p-sortIcon>
                </span>
              </ng-container>
              <ng-container *ngSwitchCase="'finalSoc'">
                <span [pSortableColumn]="col.field" >
                  {{ col.header }}
                  <p-sortIcon [field]="col.field" ariaLabel="Activate to sort"
                    ariaLabelDesc="Activate to sort in descending order"
                    ariaLabelAsc="Activate to sort in ascending order"></p-sortIcon>
                </span>
              </ng-container>
              <ng-container *ngSwitchCase="'unitConsumption'">
                <span [pSortableColumn]="col.field" >
                  {{ col.header }}
                  <p-sortIcon [field]="col.field" ariaLabel="Activate to sort"
                    ariaLabelDesc="Activate to sort in descending order"
                    ariaLabelAsc="Activate to sort in ascending order"></p-sortIcon>
                </span>
              </ng-container>
              <ng-container *ngSwitchCase="'amount'">
                <span [pSortableColumn]="col.field" >
                  {{ col.header }}
                  <p-sortIcon [field]="col.field" ariaLabel="Activate to sort"
                    ariaLabelDesc="Activate to sort in descending order"
                    ariaLabelAsc="Activate to sort in ascending order"></p-sortIcon>
                </span>
              </ng-container>
              <ng-container *ngSwitchCase="'chargingTimeInMillis'">
                <span [pSortableColumn]="col.field" >
                  {{ col.header }}
                  <p-sortIcon [field]="col.field" ariaLabel="Activate to sort"
                    ariaLabelDesc="Activate to sort in descending order"
                    ariaLabelAsc="Activate to sort in ascending order"></p-sortIcon>
                </span>
              </ng-container>
              <ng-container *ngSwitchDefault>
                {{ col.header }}
              </ng-container>
            </ng-container>
          </th>
        </ng-container>
      </tr>
    </ng-template>
    <ng-template
      pTemplate="body"
      let-transactionData
      let-columns="columns"
      let-i="rowIndex"
    >
      <tr [pSelectableRow]="transactionData">
        <ng-container *ngFor="let col of columns">
          <td *ngIf="col.isVisible">
            <ng-container [ngSwitch]="col.field">
              <ng-container *ngSwitchCase="'vehicle'">
                <td>
                  <span *ngIf="transactionData.vehicle">
                   {{ transactionData.vehicle }}
                  </span>
                  <br />
                  {{ transactionData.userName }}
                  <br />
                  {{ transactionData.userPhone }}
                </td>
              </ng-container>
              <ng-container *ngSwitchCase="'stopReason'">
                <td>
                  <span style="color: #F7685B;" *ngIf="transactionData.stopReason == 'CANCELLED'">
                       Cancelled
                  </span>
                  <span style="color: #DD700C;" *ngIf="transactionData.stopReason == 'EXPIRED'">
                    Expired
                  </span>
                  <span *ngIf="transactionData.stopReason != 'CANCELLED' && transactionData.stopReason != 'EXPIRED' ">
                            {{transactionData.stopReason}}
                  </span>
                </td>
              </ng-container>
              <ng-container *ngSwitchCase="'amount'">
                <td>&#8377;{{ transactionData.amount }}</td>
              </ng-container>
              <ng-container *ngSwitchCase="'chargingTimeInMillis'">
                <td>{{ transactionData.chargingTime }}</td>
              </ng-container>
              <ng-container *ngSwitchCase="'chargingHistory'">
                <td>
                  <span *ngIf="transactionData.scheduledTime">
                    Scheduled :
                    {{ transactionData.scheduledTime | date: 'yyyy-MM-dd' }} at
                    {{ transactionData.scheduledTime | date: 'shortTime' }}
                  </span>
                  <span *ngIf="transactionData.startTime">
                    Start :
                    {{ transactionData.startTime | date: 'yyyy-MM-dd' }} at
                    {{ transactionData.startTime | date: 'shortTime' }}
                    <br />
                    Stop : {{ transactionData.stopTime | date: 'yyyy-MM-dd' }} at
                    {{ transactionData.stopTime | date: 'shortTime' }}  
                  </span>
                </td>
              </ng-container>
              <ng-container *ngSwitchDefault>
                {{ transactionData[col.field] }}
              </ng-container>
            </ng-container>
          </td>
        </ng-container>
      </tr>
    </ng-template>
  </p-table>
</div>
<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "default" color = "#fff" type = "ball-clip-rotate-multiple" [fullScreen] = "true"></ngx-spinner>

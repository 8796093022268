import { Component, OnInit } from '@angular/core';
import { ChargerService } from '../../../services/charger/charger.service';

@Component({
  selector: 'app-charging-location',
  templateUrl: './charging-location.component.html',
  styleUrls: ['./charging-location.component.css']
})
export class ChargingLocationComponent implements OnInit {
  chargerDetail: any;

  constructor(private _chargerService : ChargerService) { }
  lat = 28.4595;
  lng = 77.0266;
  infoLat = 28.4595;
  infoLng= 77.0266;
  searchQuery: { value?} = {};
  filteredData = [];
  chargerDropDown = [];
  chargerData={}
  chargers=[]
  showChargerDetails: Boolean = false

  ngOnInit(): void {
   this.getChargingStation();
  }
  getChargingStation(){
    this._chargerService.getChargerDetails().subscribe(res=>{
      console.log(res)
      this.chargers=[]
      this.chargerDropDown=[]
      res['response'].forEach(element => {
       this.chargerDropDown.push({value :element['id']+'_'+ element['name']+'_'+element['city'], label : element['name'], address : element['address']})
        this.chargerData[element['id']+'_'+element['name']+'_'+element['city']] = {
          name: element['name'],
          address: element['address'],
          phone: element['ownerPhone'],
          lat: element['latitude'],
          lng: element['longitude'],
          openTime: element['openTime'],
          closeTime: element['closeTime'],
          totalGuns: Number(element['noOfFastChargers'])+Number(element['noOfSlowChargers']),
          googleMapLink: element['googleMapLink'],
          slowChargerPrice: element['slowChargerPrice'],
          fastChargerPrice:element['fastChargerPrice'],
          gunsAvailability: element['gunsAvailability'],
          noOfFastChargers:element['noOfFastChargers'],
          noOfSlowChargers: element['noOfSlowChargers'],
          isOpen: false,
          overallAvailabilityCount: element['gunsAvailability']['fastChargersAvailable'] + element['gunsAvailability']['slowChargersAvailable']
        }
        this.chargers.push( this.chargerData[element['id']+'_'+element['name']+'_'+element['city']])
      });
      console.log(this.chargerData)
      console.log(this.chargerDropDown)
    })
  }

  filterMultiple(event) {
    let query = event.query;
    this.filteredData = this.filter(query, this.chargerDropDown);
  }

  filter(query, charger: any[]): any[] {
    let filtered: any[] = [];
    for (let i = 0; i < charger.length; i++) {
        let currentCharger = charger[i];
        if(currentCharger.value.toLowerCase().indexOf(query.toLowerCase()) >= 0)
            filtered.push(currentCharger);
    }
    return filtered;
  }
  open(query){
   console.log(query)
   this.chargers=[]
   this.chargers.push(this.chargerData[query.value])
   this.showChargerDetails = false;
  }
  toggleChargerDetails(data){
    console.log(data)
    if(data){
      this.chargerDetail = data;
      this.showChargerDetails = true;
    }else{
       this.showChargerDetails = false;
    }
  }
  onMouseOver(data){
  console.log(data)
    data.isOpen = true;
  }
  onMouseOut(data){
    console.log(data)
      data.isOpen = false;
  }
  openMarker(data){
    this.chargers = []
    this.chargers.push(data)
  }

}

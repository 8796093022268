import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from './services/app.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  ssoId;
  moreMargin:boolean=false;
  constructor(private router: Router,public appService : AppService) {

  }

  ngOnInit() {
    this.appService.isValid = true;
  }

  marginChangeHandler(margin:number){
    if(margin>100){
      this.moreMargin=true;
    }else{
      this.moreMargin=false;
    }

  }

}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { CanActivateRouteGuard } from './guards/can-activate-route.guard';

const routes: Routes = [

  {
    path:'',
    //component:LoginComponent,
   // loadChildren:'./main/container/rides/rides.module#RidesModule',
    component:LoginComponent,
  },
  {
    path:'charging-transactions',
    //component:LoginComponent,
   // loadChildren:'./main/container/rides/rides.module#RidesModule',
   loadChildren:'./container/charging-details/charging-details.module#ChargingDetailsModule',
   canActivate:[CanActivateRouteGuard]
  },
  {
    path:'charging-location',
    //component:LoginComponent,
   // loadChildren:'./main/container/rides/rides.module#RidesModule',
   loadChildren:'./container/charging-location/charging-location.module#ChargingLocationModule',
   canActivate:[CanActivateRouteGuard]
  },
  {
    path:'drivers',
    //component:LoginComponent,
   // loadChildren:'./main/container/rides/rides.module#RidesModule',
   loadChildren:'./container/driver/driver.module#DriverModule',
   canActivate:[CanActivateRouteGuard]
  },
  {
    path:'vehicle',
    //component:LoginComponent,
   // loadChildren:'./main/container/rides/rides.module#RidesModule',
   loadChildren:'./container/vehicle/vehicle.module#VehicleModule',
   canActivate:[CanActivateRouteGuard]
  },
  {
    path:'payments',
    //component:LoginComponent,
   // loadChildren:'./main/container/rides/rides.module#RidesModule',
   loadChildren:'./container/payment/payment.module#PaymentModule',
   canActivate:[CanActivateRouteGuard]
  },
  {
    path:'help',
    //component:LoginComponent,
   // loadChildren:'./main/container/rides/rides.module#RidesModule',
   loadChildren:'./container/help/help.module#HelpModule',
   canActivate:[CanActivateRouteGuard]
  },
  {
    path:'login',
    component:LoginComponent,
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { CookieService } from 'ngx-cookie-service';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable, pipe } from 'rxjs';
import { AppService } from '../services/app.service';

import { COOKIE_URL } from '../config';

@Injectable({
  providedIn: 'root'
})
export class CanActivateRouteGuard implements CanActivate {
  constructor(private cookieService: CookieService, private router: Router, private _appService : AppService) { }


  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const context = this;
    let rolesURL = {"MENU_CHARGING_LOCATION" : "/charging-location",
                    "MENU_CHARGING_TRANSACTION" : "/charging-transaction",
                    "MENU_PAYMENTS" : "/payments",
                    "MENU_PROFILE" : "/profile",
                    "MENU_DRIVERS" : "/drivers",
                    "MENU_VEHICLE" : "/vehicle",
                    "MENU_HELP" : "/help"}
    let userMenuRoles={}
    return new Promise(function canActivate(resolve, reject) {
      context._appService.checkToken().subscribe((responseServer: any) => {
        console.log(responseServer)
        if (responseServer.statusCode >= 300) {
          context.cookieService.delete('accessToken', '/', COOKIE_URL);
          context.router.navigateByUrl('/login');
          context._appService.isValid = false
          return reject(false);
        } else {
          let ssoId = responseServer.response.sso_id
          let lastRole
          let roleExist
          context._appService.getWelletBalance(responseServer.response.fleetId).subscribe(res=>{
            console.log(res)
            if(res['status'] && res['status']=='SUCCESS')
              context._appService.setWalletBalance(res['response']) 
          })
          let username = responseServer.response['username']
          let usernameArr = username.split('.')
          console.log(usernameArr)
          if(usernameArr.length == 2){
            context._appService.setUserName(usernameArr[0].charAt(0).toUpperCase() + usernameArr[0].slice(1).toLowerCase())
          } else{
              usernameArr = username.split('@')
              context._appService.setUserName(usernameArr[0].charAt(0).toUpperCase() + usernameArr[0].slice(1).toLowerCase())
          }
          localStorage.setItem('ssoId', responseServer.response.sso_id)
          context._appService.getRolesForUser(ssoId).subscribe(
            (res: any) => {
              if(res && res['status']=="SUCCESS")
             { localStorage.setItem("userRoles", JSON.stringify(res.response))
              res.response.forEach(role => {
                if(JSON.stringify(role).indexOf("MENU") !== -1)
                  { userMenuRoles[rolesURL[role]]=role
                    lastRole=rolesURL[role]
                    if(next['_routerState'].url.includes(rolesURL[role]))
                     { roleExist =true;
                       return resolve(true);
                      }
                  }
              });
              if(!roleExist && !userMenuRoles[next['_routerState'].url])
               { if(lastRole)
                  context.router.navigateByUrl(lastRole);
                else {
                  context.cookieService.delete('accessToken', '/', COOKIE_URL);
                  context.router.navigateByUrl('/login');
                  context._appService.isValid = false
                }
                 return reject(false); 
               }
              } else {
                context.cookieService.delete('accessToken', '/', COOKIE_URL);
                context.router.navigateByUrl('/login');
                context._appService.isValid = false
                return reject(false); 
              }
            }
          )
        }
        return resolve(true);

      }, (error) => {
        context.cookieService.delete('accessToken', '/', COOKIE_URL);
        context.router.navigateByUrl('/login');
        context._appService.isValid = false;
        return reject(false); 
       });
    });
  }
}

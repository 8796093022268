import { Component, Input, OnInit } from '@angular/core';
import { TransactionService } from '../../../services/transaction/transaction.service';
import { getDuration } from '../../../util/index';
import { NgxSpinnerService } from "ngx-spinner";
import Swal from 'sweetalert2';

@Component({
  selector: 'app-completed-transactions',
  templateUrl: './completed-transactions.component.html',
  styleUrls: ['./completed-transactions.component.css'],
})
export class CompletedTransactionsComponent implements OnInit {
  constructor(private _transactionService: TransactionService, private spinner : NgxSpinnerService) {}
  transactionData = []
  offset=0
  limit=6
  start
  end
  fleetId
  cols = [
    { field: 'transactionId', header: 'Transaction ID', isVisible: true },
    { field: 'stationName', header: 'Station Name', isVisible: true },
    { field: 'chargerName', header: 'Charger Name', isVisible: true },
    { field: 'vehicle', header: 'Vehicle', isVisible: true },
    { field: 'initialSoc', header: 'Initial SOC', isVisible: true },
    { field: 'finalSoc', header: 'Final SOC', isVisible: true },
    { field: 'chargingTimeInMillis', header: 'Charging Time', isVisible: true },
    {
      field: 'unitConsumption',
      header: 'Energy Consumed (kWh)',
      isVisible: true,
    },
    { field: 'amount', header: 'Amount Charged', isVisible: true },
    {
      field: 'chargingHistory',
      header: 'Charging History',
      isVisible: true,
      width: '225px',
    },
    { field: 'stopReason', header: 'Session stop reason', isVisible: true },
    { field: 'userName', header: 'Stop Reason', isVisible: false },
    { field: 'userPhone', header: 'Stop Reason', isVisible: false },
  ]
  ngOnInit(): void {
    console.log('Complete');
  }
  getCompleteTransactions(start,end){
    this.spinner.show()
    this.fleetId = localStorage.getItem('fleetId');
    this._transactionService
    .getTransactions( this.fleetId,start,end,'completed')
    .subscribe((res) => {
      this.transactionData=[]
      console.log(res)
      if (res['status'] && res['status'] == 'SUCCESS')
        res['response'].forEach((element) => {
          let transaction = {
            transactionId: element['id'],
            stationName: element['stationName'],
            chargerName: element['chargerName'],
            vehicle: element['vehicleNumber'],
            userName: element['name'],
            userPhone: element['contactNumber'],
            initialSoc: element['initialSoc'],
            finalSoc: element['finalSoc'],
            chargingTime: element['chargingTime'],
            chargingTimeInMillis:element['chargingTimeInMillis'],
            unitConsumption: element['energyConsumed'],
            amount: element['amountCharged'],
            orderType: element['orderType'],
            stopReason: element['stopReason'],
            startTime: element['chargingHistory']['startTime'],
            stopTime: element['chargingHistory']['endTime'],
            scheduledTime: element['chargingHistory']['scheduledTime'],
          }
          this.transactionData.push(transaction)
        })
        this.spinner.hide()
    },err =>{
      console.log(err)
      Swal.fire({
        title: 'Oops!',
        icon: 'error',
        text: err.statusText,
      })
      this.spinner.hide()
    })

  }
  search(start,end){
    this.start = start
    this.end = end
    this.getCompleteTransactions(start, end);
  }
}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CompletedTransactionsComponent } from './completed-transactions/completed-transactions.component';
import { ChargingDetailsComponent } from './component/charging-details.component';
import { LiveTransactionsComponent } from './live-transactions/live-transactions.component';
import { UpcomingTransactionsComponent } from './upcoming-transactions/upcoming-transactions.component';

const routes: Routes = [
  { path:'', component:ChargingDetailsComponent,
  children: [
    { path: '', redirectTo: 'live', pathMatch: 'full' },
    { path : 'live', component: LiveTransactionsComponent },
    { path : 'upcoming', component: UpcomingTransactionsComponent},
    { path : 'completed', component: CompletedTransactionsComponent}]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ChargingDetailsRoutingModule { }

import { Component, OnInit } from '@angular/core';
import { TransactionService } from 'src/app/services/transaction/transaction.service';
import { getDuration } from '../../../util/index';
import Swal from 'sweetalert2';
import * as moment from 'moment';

@Component({
  selector: 'app-live-transactions',
  templateUrl: './live-transactions.component.html',
  styleUrls: ['./live-transactions.component.css']
})
export class LiveTransactionsComponent implements OnInit {

  constructor(private _transactionService: TransactionService) { }
  transactionData =[]
  fleetId
  timerId = null;
  cols = [
    { field: 'transactionId',header: 'Transaction ID', isVisible:true},
    { field: 'stationName', header: 'Station Name', isVisible: true },
    { field: 'chargerName', header: 'Charger Name', isVisible: true },
    { field: 'vehicle', header: 'Vehicle', isVisible: true },
    { field: 'createdTimestamp', header: 'Created Time', isVisible: true },
    { field: 'initialSoc', header: 'Initial SOC', isVisible: true },
    { field: 'finalSoc', header: 'Current SOC', isVisible: true },
    { field: 'chargingTime', header: 'Charging Time', isVisible: true },
    { field: 'unitConsumption', header: 'Energy Consumed (kWh)', isVisible: true },
    { field: 'amount', header: 'Amount Payable', isVisible: true },
    { field: 'action', header: 'End Session', isVisible: true }
  ]
  ngOnInit(): void {
    this.getLiveTransactions()
  }
  ngOnDestroy() {
    clearInterval(this.timerId);
  }
  getLiveTransactions(){
    this.fleetId = localStorage.getItem('fleetId');
    this._transactionService
    .getTransactions(this.fleetId,null,null,'live')
    .subscribe((res) => {
      this.transactionData=[]
      if (res['status'] && res['status'] == 'SUCCESS')
        res['response'].forEach((element) => {
          let transaction = {
            transactionId: element['transactionId'],
            stationName: element['chargingStation']['name'],
            chargerName: element['charger']['chargeBoxId'],
            vehicle: element['vehicle']['vehicleNumber'],
            userName: element['transaction']['userName'],
            userPhone: element['transaction']['userPhone'],
            initialSoc: element['transaction']['startSoc'],
            finalSoc: element['transaction']['endSoc'],
            chargingTime: getDuration(
              element['transaction']['startTimestampDT'],
               null, null
            ),
            unitConsumption: Number(element['status']['units']).toFixed(2),
            amount: Number(element['status']['chargingCost']).toFixed(2),
            createdTimestamp: element['transaction']['createdTimestamp']
          }
          this.transactionData.push(transaction)
        })
        if (!this.timerId) {
          this.timerId = setTimeout(() => {
            this.getLiveTransactions();
            this.timerId = null;
          }, 20 * 1000);
        }
      },
      err => {
        if (!this.timerId) {
          this.timerId = setTimeout(() => {
            this.getLiveTransactions();
            this.timerId = null;
          }, 20 * 1000);
        }
    })

  }
stopTransaction(transactionId){
  
  Swal.fire({ 
      title: "Warning!",
      html: ` Transaction Id : `+transactionId+`<br/><br/>Are you sure you want to end this charging session?`,
      icon: "warning",
      cancelButtonText:"No, keep it",
      confirmButtonText: "Yes, stop it!",
      confirmButtonColor: '#2C66E3',
      showCancelButton: true
    }).then(result =>{
       if(result.value == true){
        this._transactionService.stopTransaction(transactionId).subscribe(res=>{
          console.log(res)
          if(res['status']== "SUCCESS")
          Swal.fire({
            title: "Success",
            icon: "success",
            text: "Sucessfully Stoped"
          })
          this.getLiveTransactions()
        })
       }
    })

}

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule } from '@angular/forms';
import { CalendarModule } from 'primeng/calendar';
import { NgxSpinnerModule } from "ngx-spinner";


import { ChargingDetailsRoutingModule } from './charging-details-routing.module';
import { ChargingDetailsComponent } from './component/charging-details.component';
import { LiveTransactionsComponent } from './live-transactions/live-transactions.component';
import { CompletedTransactionsComponent } from './completed-transactions/completed-transactions.component';
import { UpcomingTransactionsComponent } from './upcoming-transactions/upcoming-transactions.component';
import { TableModule } from 'primeng/table';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';


@NgModule({
  declarations: [ChargingDetailsComponent, LiveTransactionsComponent, CompletedTransactionsComponent, UpcomingTransactionsComponent],
  imports: [
    CommonModule,
    ChargingDetailsRoutingModule,
    FormsModule,
    CalendarModule,
    TableModule,
    InfiniteScrollModule,
    NgxSpinnerModule
  ]
})
export class ChargingDetailsModule { }

<div class="search-card">
    <div class="station-name">{{chargerDetail.name}}</div>
    <div class="display-flex station-address"><span><i class="fas fa-map-marker-alt"></i></span> <span style="padding-left: 4px">{{chargerDetail.address}}</span> <div style="justify-content: flex-end; margin-left: 100px;"><a href="{{chargerDetail.googleMapLink}}" target="_blank" style="cursor: pointer;"><img src="../../../../assets/direction.png"></a></div></div>
    <div class="display-flex station-address"><span><i class="fas fa-clock"></i> <span style="padding-left: 4px">Open: {{chargerDetail.openTime}}-{{chargerDetail.closeTime}}</span></span></div>
    <div class="display-flex station-address"><span><i class="fas fa-phone"></i> <span style="padding-left: 4px"> {{chargerDetail.phone}} </span> </span></div>
    
    <div class="connector-type">
       Connector Type
       <div *ngIf="chargerDetail.noOfFastChargers" class="display-flex connector-detail" style="margin-top: 15px;">
            <div> <img src="../../../../assets/fastCharger.png"></div>
            <div style="padding-left: 5px;"> {{chargerDetail.gunsAvailability.fastChargerName}} <br/> <b>Supply :</b> {{chargerDetail.gunsAvailability.fastChargersSupplyUnit}} </div>
            <div style="padding-left: 5px;"> {{chargerDetail.noOfFastChargers}} guns ({{chargerDetail.gunsAvailability.fastChargersAvailable}} Available) <br/> <span *ngIf="chargerDetail.fastChargerPrice"><b>Unit cost:</b>  Rs. {{chargerDetail.fastChargerPrice.maxTransactionPricePerUnit}}/kwh</span></div>
       </div>
       <div *ngIf="chargerDetail.noOfSlowChargers" class="display-flex connector-detail">
        <div> <img src="../../../../assets/slowCharger.png" width="37px"></div>
        <div style="padding-left: 5px;"> {{chargerDetail.gunsAvailability.slowChargerName}} <br/> <b>Supply :</b> {{chargerDetail.gunsAvailability.slowChargerSupplyUnit}} </div>
        <div style="padding-left: 5px;"> {{chargerDetail.noOfSlowChargers}} guns ({{chargerDetail.gunsAvailability.slowChargersAvailable}} Available) <br/> <span *ngIf="chargerDetail.slowChargerPrice"><b>Unit cost:</b>  Rs.  {{chargerDetail.slowChargerPrice.maxTransactionPricePerUnit}}/hr</span></div>
   </div>
    </div>
</div>
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from '../header/header/header.component';

const route = [
  { path: '**', redirectTo: 'charging-location' }
];

@NgModule({
  declarations: [HeaderComponent],
  imports: [
    CommonModule,
    RouterModule.forRoot(route)
  ],providers: [],
  exports:[HeaderComponent]
})
export class HeaderModule { }

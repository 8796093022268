import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule,HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppInterceptor } from './app.interceptor';

import { CalendarModule } from 'primeng/calendar';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { HeaderModule } from '../app/header/header.module';
import { ChargingLocationModule } from './container/charging-location/charging-location.module';
import { ChargingDetailsModule } from './container/charging-details/charging-details.module';
import { DriverModule } from './container/driver/driver.module';
import { VehicleModule } from './container/vehicle/vehicle.module';
import { HelpModule } from './container/help/help.module';
import { PaymentModule } from './container/payment/payment.module';
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HeaderModule,
    HttpClientModule,
    ChargingLocationModule,
    BrowserAnimationsModule,
    ChargingDetailsModule,
    CalendarModule,
    DriverModule,
    VehicleModule,
    HelpModule,
    PaymentModule
  ],
  providers: [
    {
      provide:HTTP_INTERCEPTORS,
      useClass:AppInterceptor,
      multi:true
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DropdownModule } from 'primeng/dropdown';
import { FormsModule } from '@angular/forms';
import { DialogModule } from 'primeng/dialog';
import { TableModule } from 'primeng/table';
import { DriverRoutingModule } from './driver-routing.module';
import { DriverComponent } from './component/driver/driver.component';


@NgModule({
  declarations: [DriverComponent],
  imports: [
    CommonModule,
    DriverRoutingModule,
    TableModule,
    DialogModule,
    FormsModule,
    DropdownModule
  ]
})
export class DriverModule { }

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GET_CHARGING_STATION } from '../../config/index';

@Injectable({
  providedIn: 'root'
})
export class ChargerService {

  constructor(private _http: HttpClient) { }
  getChargerDetails(){
     return this._http.get(GET_CHARGING_STATION);
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GET_TRANSACTION , CANCEL_RESERVATION ,STOP_TRANSACTION, GET_COMPLETED_TRANSACTION, GET_LIVE_TRANSACTION, GET_UPCOMING_RESERVATION} from '../../config/index';

@Injectable({
  providedIn: 'root'
})
export class TransactionService {
  constructor(private _http: HttpClient) { }
  
  getTransactions(fleetId,start,end,transactionType){
    if(transactionType == 'live')
      return this._http.get(GET_LIVE_TRANSACTION+"?fleetId="+fleetId);
    if(transactionType == 'completed')
      return this._http.get(GET_COMPLETED_TRANSACTION+'/'+fleetId+"?startTime="+start+"&endTime="+end);
    return this._http.get(GET_UPCOMING_RESERVATION+"?fleetId="+fleetId+"&startTime="+start+"&endTime="+end);
  }
  cancelUpcomingReservation(reservationRequestId){
    return this._http.post(CANCEL_RESERVATION+reservationRequestId+'/cancel',{})
  }
  stopTransaction(transactionId){
    return this._http.get(STOP_TRANSACTION+transactionId)
  }

}


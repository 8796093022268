<div class="container" style="min-width: 1100px;max-width: 2500px;padding: 0px;margin: 0px;">
<div class="charger-block">
<div class="charger-block-header">List of charging stations</div>
<div class="auto-complete">
    <p-autoComplete [(ngModel)]="searchQuery" [suggestions]="filteredData"
                (completeMethod)="filterMultiple($event)" [minLength]="1" placeholder="&#xf002;  Search" field="label"
                [style]="{'width':'300px !important'}" [dropdown]="true" (onSelect)='open(searchQuery)'> 
        <ng-template let-searchQuery pTemplate="item">
        <div style="border: 1px solid #D5D5D5;flex-direction: row;background: white;display: flex;">
              <div class="drop-down">
                     <span class="station-name">{{searchQuery.label}}</span>
                     <span class="display-flex station-address"> <span><i class="fas fa-map-marker-alt"></i></span> <span style="padding-left: 4px">{{searchQuery.address}}</span></span>
              </div>
       </div>
        </ng-template>
    </p-autoComplete>
</div>
<span class="back" *ngIf="!showChargerDetails">  <a (click)="getChargingStation()" > <i class="fas fa-angle-left"></i>  &nbsp; All</a> </span>
    <div *ngFor="let data of chargers;let i = index">
    <div class="search-card"*ngIf='!showChargerDetails'  (click)="toggleChargerDetails(data)" style="cursor:pointer" >
        <div class="station-name">{{data.name}}</div>
        <div class="display-flex station-address"> <span><i class="fas fa-map-marker-alt"></i></span> <span style="padding-left: 4px">{{data.address}}</span></div>
        <div class="display-flex guns" style="justify-content: space-between;">
           <span><i class="fas fa-bolt"></i> {{data.totalGuns}} Guns</span>
           <span><i class="fas fa-clock"></i> {{data.openTime}}-{{data.closeTime}}</span>
        </div>
    </div>
   </div>
    <span class="back" *ngIf="showChargerDetails">  <a (click)="toggleChargerDetails(null)" > <i class="fas fa-angle-left"></i>  &nbsp; Back to results</a> </span>
    <charger-result *ngIf="showChargerDetails" [chargerDetail] ='chargerDetail'></charger-result> 
</div>

<agm-map [latitude]="lat" [longitude]="lng" [zoom]="11" [maxZoom]="18" [minZoom]="6">
    <agm-marker  *ngFor="let data of chargers;let i = index" 
                            [latitude]="data.lat" [longitude]="data.lng" 
                            [iconUrl]="  {
                                url: '../../../../assets/chargerMarker.png',
                                scaledSize: {
                                    width: 40,
                                    height: 45
                                }
                            }"  (mouseOver)="onMouseOver(data)" (mouseOut)="onMouseOut(data)" (markerClick)="openMarker(data)" >
     <agm-info-window  #infoWindow [latitude]="data.lat" [longitude]="data.lng" [isOpen]="data.isOpen" [maxWidth]="2000" maxHeight="600px">
        <div class="station-name">{{data.name}}</div>
        <div *ngIf="data.overallAvailabilityCount > 0" class="available">
            Available
        </div>
        <div *ngIf="data.overallAvailabilityCount <= 0" class="unavailable">
            Unavailable
        </div>
         </agm-info-window>
    </agm-marker>

</agm-map>
</div>
import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.css']
})
export class HelpComponent implements OnInit {

  constructor(private _appService: AppService) { }
  faqs=[]
  ngOnInit(): void {
    this._appService.getFaq().subscribe(res=>{
      console.log(res)
      if(res['status'] && res['status']=="SUCCESS"){
        res['response'].forEach(element => {
          let faq = { question: element['question'] , answer: element['answer'] }
          this.faqs.push(faq)
        });
        console.log(this.faqs);
      }
    })
  }

}

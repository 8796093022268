import { Component, OnInit,ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { AppService } from 'src/app/services/app.service';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-charging-details',
  templateUrl: './charging-details.component.html',
  styleUrls: ['./charging-details.component.css']
})
export class ChargingDetailsComponent implements OnInit {

  constructor(private router: Router, private _appService: AppService) { }
  childComponent
  selectedTab
  showCalendar = false
  selectedDates = [moment().startOf('d').toDate(), moment().startOf('d').add(1, 'd').toDate()];
  ngOnInit(): void {
    console.log(this.router.url);
    this.showCalendar = !this.router.url.includes('live')
  }
  search(){
    this.childComponent.search(this.selectedDates[0].getTime(), this.selectedDates[1].getTime());
  }
  onActivate(componentReference) {
    this.childComponent = componentReference;
    this.showCalendar = !this.childComponent.constructor.name.includes('Live')
    if(this.childComponent.constructor.name != 'LiveTransactionsComponent') 
     this.search()
 }

 downloadCSV(){
  // 1.CSV_FOR_PAST_TRANSACTIONS,
  // 2.CSV_FOR_RESERVATIONS,
  // 3.CSV_FOR_VEHICLES,
  // 4.CSV_FOR_DRIVERS
  let fleetId = localStorage.getItem('fleetId')
  let csvType
  console.log(this.router.url)
  console.log(this.router.url.includes('completed'))
  if(this.router.url.includes('upcoming'))
    csvType = "CSV_FOR_RESERVATIONS"
  if(this.router.url.includes('completed'))
    csvType = "CSV_FOR_PAST_TRANSACTIONS"
   this._appService.downloadCSV(csvType,fleetId,this.selectedDates[0].getTime(),this.selectedDates[1].getTime()).subscribe(data => 
    saveAs(data, `transactions.csv`),
     err => { }
   )
 }

}

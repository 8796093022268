import { Component, OnInit } from '@angular/core';
import { TransactionService } from 'src/app/services/transaction/transaction.service';
import { getDuration } from '../../../util/index';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from "ngx-spinner";
@Component({
  selector: 'app-upcoming-transactions',
  templateUrl: './upcoming-transactions.component.html',
  styleUrls: ['./upcoming-transactions.component.css']
})
export class UpcomingTransactionsComponent implements OnInit {

  constructor(private _transactionService: TransactionService,private spinner : NgxSpinnerService) { }
  transactionData =[]
  offset=0
  limit=6
  start
  end
  fleetId
  cols = [
    { field: 'bookingId',header: 'Booking ID', isVisible:true},
    { field: 'stationName', header: 'Station Name', isVisible: true },
    { field: 'chargerName', header: 'Charger Name', isVisible: true },
    { field: 'vehicle', header: 'Vehicle', isVisible: true },
    { field: 'scheduledTime', header: 'Schedule Time', isVisible: true, width: '200px', },
    { field: 'duration', header: 'Planned Duration', isVisible: true },
    { field: 'unitConsumption', header: 'Estimated Energy Consumption (kWh)', isVisible: true },
    { field: 'payment', header: 'Estimated Amount ', isVisible: true },
    { field: 'action', header: 'Cancel Session', isVisible: true }
  ]
  ngOnInit(): void {
    console.log("upcoming")
  }
  getUpcomingTransactions(start,end){
    this.fleetId = localStorage.getItem('fleetId');
    this.spinner.show()
    this._transactionService
    .getTransactions(this.fleetId ,start,end,'upcoming')
    .subscribe((res) => {
      this.transactionData =[]
      console.log(res)
      if (res['status'] && res['status'] == 'SUCCESS')
        res['response'].forEach((element) => {
          let transaction = {
            bookingId: element['reservationRequestId'],
            stationName: element['chargingStation']['name'],
            chargerName: element['charger']['chargeBoxId'],
            vehicle: element['reservation']['vehicleNumber'],
            userName: element['reservation']['userName'],
            userPhone: element['reservation']['userPhone'],
            unitConsumption: Number(element['reservation']['powerConsumption']).toFixed(2),
            duration: element['reservation']['chargingDuration'],
            payment: Number(element['reservation']['payment']).toFixed(2),
            createdTimestamp: element['reservation']['createdTimeStamp'],
            scheduledTime: element['reservation']['startDatetimeDT']
          }
          this.transactionData.push(transaction)
        })
        this.spinner.hide()
    }, error => {
      console.log(error)
      Swal.fire({
        title: 'Oops!',
        icon: 'error',
        text: error.statusText,
      })
      this.spinner.hide()
    })

  }
  search(start,end){
    this.start =start;
    this.end =end;
    this.getUpcomingTransactions(start, end);
  }
  
  cancelReservation(rideRequestId){
    Swal.fire({ 
        title: "Warning!",
        html: ` Booking Id : `+rideRequestId+`<br/><br/>Are you sure you want to cancel booking?`,
        icon: "warning",
        cancelButtonText:"No, keep it",
        confirmButtonText: "Yes, Cancel",
        confirmButtonColor: '#2C66E3',
        showCancelButton: true
      }).then(result =>{
         if(result.value == true){
          this._transactionService.cancelUpcomingReservation(rideRequestId).subscribe(res=>{
            console.log(res)
            if(res['status']== "SUCCESS")
            Swal.fire({
              title: "Success",
              icon: "success",
              text: "Sucessfully Cancelled"
            })
            this.getUpcomingTransactions(this.start,this.end)
          })
         }
      })

  }
}

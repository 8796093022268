import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { ChargingLocationRoutingModule } from './charging-location-routing.module';
import { ChargingLocationComponent } from './component/charging-location.component';
import { AgmCoreModule } from '@agm/core';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ChargerResultComponent } from './charger-result/charger-result.component';
import { AgmSnazzyInfoWindowModule } from '@agm/snazzy-info-window';


@NgModule({
  declarations: [ChargingLocationComponent, ChargerResultComponent],
  imports: [
    CommonModule,
    ChargingLocationRoutingModule,
    AutoCompleteModule,
    FormsModule,
    AgmSnazzyInfoWindowModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCMmbclH3d8bR8FnQdU5S4NIY0AknFzZSI'
      /* apiKey is required, unless you are a 
      premium customer, in which case you can 
      use clientId 
      */
    }),
  ]
})
export class ChargingLocationModule { }

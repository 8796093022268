import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GET_PAYMENT_LIST,ADD_AMOUNT, RAZORPAY_INITIATE,CHECK_PAYMENT_STATUS} from '../../config/index';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  constructor(private _http: HttpClient) { }
  getPaymentList(fleetId,start,end){
    return this._http.get(GET_PAYMENT_LIST+fleetId+"?startTime="+start+"&endTime="+end)
  }
  addAmount(body){
    return  this._http.post(ADD_AMOUNT,body);
  }
  razorpayInitiate(body,orderId,email,phone){ 
    return this._http.get(RAZORPAY_INITIATE+orderId+"?email="+email+"&phoneNo="+phone, body);
  }
  checkStatus(orderId){
     return this._http.get(CHECK_PAYMENT_STATUS+orderId)
  }
}

import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { AppService } from '../services/app.service';
import { Headers } from '../model/api.header';
import { CookieService } from 'ngx-cookie-service';
import { COOKIE_URL } from '../config/index';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  constructor(private router:Router, public _appService : AppService , private cookieService: CookieService) {
  }
  name

  ngOnInit() {
    const token =this.cookieService.get('accessToken');
    if(token){
      this.checkToken()
    }else{
      this.router.navigateByUrl('/login');
      this._appService.isValid=false;
    }
   }

  login(data:NgForm){
    this._appService.login(data.value).subscribe(
      (responseServer)=>{
        console.log(responseServer)
         if(responseServer['status'] && responseServer['status'] == 'SUCCESS'){
          let response = responseServer['response']
          Headers.clearHeaders();
          Headers.addHeaders({key : 'Authorization', value : 'Bearer '+ response.access_token})
          localStorage.setItem('fleetId',response.fleetId)
          this.cookieService.set("accessToken", response.access_token, response.expires_in, "/",COOKIE_URL,null,"Lax");
          this.getWalletBalance(response.fleetId)
          this.checkToken()
          this._appService.isValid = true;
          this.router.navigateByUrl('/charging-location');
         }
      },
      (err)=>{
        Swal.fire({
          title: "Unauthorized!",
          text: "Invaild username or password",
          icon: "error",
          confirmButtonText: "OK",
          confirmButtonColor: '#1d3a88'
        })
      }
    )
  }
  getWalletBalance(fleetId){
     this._appService.getWelletBalance(fleetId).subscribe(res=>{
       console.log(res)
       if(res['status'] && res['status']=='SUCCESS')
         this._appService.setWalletBalance(res['response']) 
     })
  }
  checkToken(){
    this._appService.checkToken().subscribe(res=>{
      console.log(res)
      if(res['status']=='SUCCESS' && res['response']['username']){
        console.log(res['response']['username'])
          let username = res['response']['username']
          let usernameArr = username.split('.')
          console.log(usernameArr)
          if(usernameArr.length == 2){
              this.name = usernameArr[0].charAt(0).toUpperCase() + usernameArr[0].slice(1).toLowerCase()
          } else{
              usernameArr = username.split('@')
              this.name = usernameArr[0].charAt(0).toUpperCase() + usernameArr[0].slice(1).toLowerCase()
          }
          this.getWalletBalance(res['response'].fleetId)
          this._appService.setUserName(this.name)
          this._appService.isValid = true;
          this.router.navigateByUrl('/charging-location');
      }
    })
  }

}

<div class="table-container">
  <p-table #dt [columns]="cols" [value]="transactionData" sortMode="multiple"
  [paginator]="true" [rows]="10">
  <ng-template pTemplate="caption">
    <div style="display: flex; position: absolute; margin-top: -75px;">
      <input  class="form-control" type="text" style="width: 250px;" pInputText size="50" placeholder="Search"
      (input)="dt.filterGlobal($event.target.value, 'contains')" lazy="false" />
  </div>
  </ng-template>
    <ng-template pTemplate="header" let-columns>
      <tr>
        <ng-container *ngFor="let col of columns">
          <th *ngIf="col.isVisible" [ngStyle]="{ width: col.width }">
            <ng-container [ngSwitch]="col.field">
                <ng-container *ngSwitchCase="'transactionId'">
                  <span [pSortableColumn]="col.field" >
                    {{ col.header }}
                    <p-sortIcon [field]="col.field" ariaLabel="Activate to sort"
                      ariaLabelDesc="Activate to sort in descending order"
                      ariaLabelAsc="Activate to sort in ascending order"></p-sortIcon>
                  </span>
                </ng-container>
                <ng-container *ngSwitchCase="'stationName'">
                  <span [pSortableColumn]="col.field" >
                    {{ col.header }}
                    <p-sortIcon [field]="col.field" ariaLabel="Activate to sort"
                      ariaLabelDesc="Activate to sort in descending order"
                      ariaLabelAsc="Activate to sort in ascending order"></p-sortIcon>
                  </span>
                </ng-container>
                <ng-container *ngSwitchCase="'chargerName'">
                  <span [pSortableColumn]="col.field" >
                    {{ col.header }}
                    <p-sortIcon [field]="col.field" ariaLabel="Activate to sort"
                      ariaLabelDesc="Activate to sort in descending order"
                      ariaLabelAsc="Activate to sort in ascending order"></p-sortIcon>
                  </span>
                </ng-container>
                <ng-container *ngSwitchCase="'initialSoc'">
                  <span [pSortableColumn]="col.field" >
                    {{ col.header }}
                    <p-sortIcon [field]="col.field" ariaLabel="Activate to sort"
                      ariaLabelDesc="Activate to sort in descending order"
                      ariaLabelAsc="Activate to sort in ascending order"></p-sortIcon>
                  </span>
                </ng-container>
                <ng-container *ngSwitchCase="'finalSoc'">
                  <span [pSortableColumn]="col.field" >
                    {{ col.header }}
                    <p-sortIcon [field]="col.field" ariaLabel="Activate to sort"
                      ariaLabelDesc="Activate to sort in descending order"
                      ariaLabelAsc="Activate to sort in ascending order"></p-sortIcon>
                  </span>
                </ng-container>
                <ng-container *ngSwitchCase="'unitConsumption'">
                  <span [pSortableColumn]="col.field" >
                    {{ col.header }}
                    <p-sortIcon [field]="col.field" ariaLabel="Activate to sort"
                      ariaLabelDesc="Activate to sort in descending order"
                      ariaLabelAsc="Activate to sort in ascending order"></p-sortIcon>
                  </span>
                </ng-container>
                <ng-container *ngSwitchCase="'amount'">
                  <span [pSortableColumn]="col.field" >
                    {{ col.header }}
                    <p-sortIcon [field]="col.field" ariaLabel="Activate to sort"
                      ariaLabelDesc="Activate to sort in descending order"
                      ariaLabelAsc="Activate to sort in ascending order"></p-sortIcon>
                  </span>
                </ng-container>
                <ng-container *ngSwitchCase="'chargingTime'">
                  <span [pSortableColumn]="col.field" >
                    {{ col.header }}
                    <p-sortIcon [field]="col.field" ariaLabel="Activate to sort"
                      ariaLabelDesc="Activate to sort in descending order"
                      ariaLabelAsc="Activate to sort in ascending order"></p-sortIcon>
                  </span>
                </ng-container>
                <ng-container *ngSwitchDefault>
                  {{ col.header }}
                </ng-container>
            </ng-container>
          </th>
        </ng-container>
      </tr>
    </ng-template>
    <ng-template
      pTemplate="body"
      let-transactionData
      let-columns="columns"
      let-i="rowIndex"
    >
      <tr [pSelectableRow]="transactionData">
        <ng-container *ngFor="let col of columns">
          <td *ngIf="col.isVisible">
            <ng-container [ngSwitch]="col.field">
              <ng-container *ngSwitchCase="'vehicle'">
                <td>
                  <span *ngIf="transactionData.vehicle">
                   {{ transactionData.vehicle }}
                  </span>
                  <br />
                  {{ transactionData.userName }}
                  <br />
                  {{ transactionData.userPhone }}
                </td>
              </ng-container>
              <ng-container *ngSwitchCase="'amount'">
                <td>&#8377;{{ transactionData.amount }}</td>
              </ng-container>
              <ng-container *ngSwitchCase="'createdTimestamp'">
                <td>
                  {{ transactionData.createdTimestamp | date: 'yyyy-MM-dd' }} at
                  {{ transactionData.createdTimestamp | date: 'shortTime' }}

                </td>
              </ng-container>
              <ng-container *ngSwitchCase="'action'">
                <td>
                  <a style="cursor: pointer;" (click)="stopTransaction(transactionData.transactionId)"> <i class="fas fa-times-circle"></i></a>
                </td>
              </ng-container>
              <ng-container *ngSwitchDefault>
                {{ transactionData[col.field] }}
              </ng-container>
            </ng-container>
          </td>
        </ng-container>
      </tr>
    </ng-template>
  </p-table>
</div>

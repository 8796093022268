<div class="container">
    <div class="header">
        We are here to help you.
    </div>
    <div class="email-container">
        <span><i class="far fa-envelope"></i> </span> <span style="position: absolute;"> Drop a line on <a href = "mailto: support@blu-smart.com">support@blu-smart.com</a></span>
    </div>
    <div class="faq">Faq</div>
<p-accordion >
    <div *ngFor ="let faq of faqs">
    <p-accordionTab [header]="faq.question">
       {{faq.answer}}
    </p-accordionTab>
   </div>
</p-accordion>
</div>
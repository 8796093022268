<div class="container">
  <div class="table-container">
    <p-table #dt [columns]="cols" [value]="paymentData" sortMode="multiple"
    [paginator]="true" [rows]="10" totalRecords="10000" >
    <ng-template pTemplate="caption" class="header">
      
      <div style="display: flex; justify-content: space-between;">
        <div style="display: flex;">
        <input  class="form-control" type="text" style="width: 250px;margin-right: 5px;" pInputText size="50" placeholder="Search"
        (input)="dt.filterGlobal($event.target.value, 'contains')" lazy="false" />
        <p-calendar  appendTo="body" [(ngModel)]="selectedDates" [showIcon]="true" selectionMode="range"></p-calendar>
        <button class="btn btn-primary" style="padding: 0px 12px;" (click)="getPaymentData()"><i class="fas fa-search" style="color: #fff; padding: 0px; margin: 0px;"></i></button>
        </div>
        <div style="text-align: center;">
          <button class="btn btn-primary" (click)="showAddMoneyDialog()"> Add Money</button>
          <button class="btn btn-basic" (click)="downloadCSV()"><i class="fas fa-download"></i></button>
       </div>
    </div>
    
    </ng-template>
          <ng-template pTemplate="header" let-columns style="box-shadow: 6px 0px 18px rgba(0, 0, 0, 0.06);">
            <tr>
              <ng-container *ngFor="let col of columns">
                <th *ngIf="col.isVisible" [ngStyle]="{ width: col.width }">
                  <ng-container [ngSwitch]="col.field">
                    <ng-container *ngSwitchCase="'transactionId'">
                      <span [pSortableColumn]="col.field" >
                        {{ col.header }}
                        <p-sortIcon [field]="col.field" ariaLabel="Activate to sort"
                          ariaLabelDesc="Activate to sort in descending order"
                          ariaLabelAsc="Activate to sort in ascending order"></p-sortIcon>
                      </span>
                    </ng-container>
                    <ng-container *ngSwitchCase="'reservationId'">
                      <span [pSortableColumn]="col.field" >
                        {{ col.header }}
                        <p-sortIcon [field]="col.field" ariaLabel="Activate to sort"
                          ariaLabelDesc="Activate to sort in descending order"
                          ariaLabelAsc="Activate to sort in ascending order"></p-sortIcon>
                      </span>
                    </ng-container>
                    <ng-container *ngSwitchCase="'dateAndTime'">
                      <span [pSortableColumn]="col.field" >
                        {{ col.header }}
                        <p-sortIcon [field]="col.field" ariaLabel="Activate to sort"
                          ariaLabelDesc="Activate to sort in descending order"
                          ariaLabelAsc="Activate to sort in ascending order"></p-sortIcon>
                      </span>
                    </ng-container>
                    <ng-container *ngSwitchCase="'paymentOrderStatus'">
                      <br />
                      <p-multiSelect
                        [options]="orderStatusType"
                        defaultLabel="All"
                        (onChange)="dt.filter($event.value, col.field, 'in')"
                        [style]="{ width: '90px', 'margin-top': '5px' }"
                        [panelStyle]="{ width: '170px' }"
                      ></p-multiSelect>
                    </ng-container>
                    <ng-container *ngSwitchDefault>
                      {{ col.header }}
                    </ng-container>
                  </ng-container>
                </th>
              </ng-container>
            </tr>
            <!-- <tr *ngIf="displayNone && loading == false" >
              <td colspan="11" style="font-size: 13px !important; padding: 10% !important; text-align: center;">
                  No data found
              </td>
          </tr> -->
            <!-- <div *ngIf="loading == true"
                style="display: flex;width:100%;height:100%;margin-left: 46em; margin-top: 3em;margin-bottom: 3em;">
                <p-progressSpinner [style]="{width: '50px', height: '50px',margin:'auto'}" strokeWidth="8" fill="#323232"
                  animationDuration=".5s"></p-progressSpinner>
              </div> -->
          </ng-template>
          <ng-template
            pTemplate="body"
            let-paymentData
            let-columns="columns"
            let-i="rowIndex"
          >
            <tr [pSelectableRow]="paymentData">
              <ng-container *ngFor="let col of columns">
                <td *ngIf="col.isVisible">
                  <ng-container [ngSwitch]="col.field">
                    <ng-container *ngSwitchCase="'amt'">
                      <td>&#8377;{{ paymentData.amt }}</td>
                    </ng-container>
                    <ng-container *ngSwitchCase="'dateAndTime'">
                      <td>{{ paymentData.dateAndTime | date: 'yyyy-MM-dd' }} at
                        {{ paymentData.dateAndTime | date: 'shortTime' }}</td>
                    </ng-container>
                    <ng-container *ngSwitchCase="'closingBalance'">
                        <td>&#8377;{{ paymentData.closingBalance }}</td>
                    </ng-container>
                    <ng-container *ngSwitchCase="'paymentOrderType'">
                      <td>
                       <span style="width: 90px !important;display: block;" class="order" [ngClass]="getOrderTypeClass(paymentData.paymentOrderType)"> {{getAnalogyOrder(paymentData.paymentOrderType)}} </span>
                      </td>
                    </ng-container>
                    <ng-container *ngSwitchCase="'bookingDetails'">
                        <td>
                            {{paymentData.bookingDetails.id}} <br/>
                            {{paymentData.bookingDetails.vehicleNumber}}
                        </td>
                    </ng-container>
                    <ng-container *ngSwitchCase="'chargingDetails'">
                        <td *ngIf="paymentData.chargingDetails">
                           Station- {{paymentData.station}} <br/>
                           Charger- {{paymentData.charger}}
                        </td>
                    </ng-container>

                    <ng-container *ngSwitchCase="'action'">
                        <td>
                          <!-- <a style="cursor: pointer;" > <i class="fas fa-shield-virus"></i></a> -->
                          <span *ngIf="paymentData.isToDownloadReceipt">
                            <a style="cursor: pointer;" (click)="downloadReceipt(paymentData.id)" > <i class="fas fa-download"></i></a>
                          </span>
                        </td>
                      </ng-container>

                    <ng-container *ngSwitchDefault>
                      {{ paymentData[col.field] }}
                    </ng-container>
                  </ng-container>
                </td>
              </ng-container>
            </tr>
          </ng-template>
        </p-table>
      </div>      
</div>
<p-dialog [(visible)]="showAddMoney" header="Add money to Wallet" [style]="{width: '50vw'}">
  <div class="payment-container">
      <div style="padding: 16px 0px 46px 0px;font-weight: 500;font-size: 18px;text-align: center;"> Available Balance : &#8377; {{currentAmount | number}}</div>
      <div class="input-group mb-3" class="row">
          <span class="ui-float-label" style="width: 100%;">
          <label for="username" style="color: #2C66E3;font-weight: 500; margin:0px">Enter Amount</label>
          <input pInputText type="number" class="form-control  customFormInput" style="padding-left: 15px;" id="name" [(ngModel)]="amount" >
          </span>
      </div>
      <br/>

      <div class="dialog-buttons">
          <button class="btn btn-primary" (click)="updateAmount()" style="margin-left: 20px;"> Proceed</button>
      </div>

  </div>
</p-dialog>

<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "default" color = "#fff" type = "ball-clip-rotate-multiple" [fullScreen] = "true"><p style="color: white" > Processing Payment... </p></ngx-spinner>
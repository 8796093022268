import { Component, OnInit } from '@angular/core';
import { VehicleService } from '../../../services/vehicle/vehicle.service';
import Swal from 'sweetalert2'
import { SelectItem } from 'primeng/api';
import { AppService } from 'src/app/services/app.service';
import { saveAs } from 'file-saver'

@Component({
  selector: 'app-vehicle',
  templateUrl: './vehicle.component.html',
  styleUrls: ['./vehicle.component.css']
})
export class VehicleComponent implements OnInit {
  constructor(private _vehicleService: VehicleService, private _appService: AppService) {}
  vehicleData = []
  offSet = 0
  limit = 10
  action
  fleetId
  updateDetailsDialog = false
  showVarientDropDown= false
  vehicleManufacturerOption:  SelectItem[] =[];
  vehicleModelOption: SelectItem[]=[]
  updateVehicleDto = {
    id: null,
    vehicleNumber: '',
    model: null,
    manufacturer: null,
    fleetId: null,
    apiSource: 'FLEET_DASHBOARD'
  }
  cols = [
    { field: 'vehicleNumber', header: 'Registration Number', isVisible: true },
    { field: 'manufacturer', header: 'Car Manufacturer', isVisible: true },
    { field: 'model', header: 'Car Model', isVisible: true },
    { field: 'variant', header: 'Car Variant', isVisible: true },
    { field: 'kmRange', header: 'Vehicle Range', isVisible: true },
    { field: 'chargerType', header: 'Supported Connectors', isVisible: true },
    { field: 'action', header: 'Action', isVisible: true },
  ]
  ngOnInit(): void {
    this._vehicleService.getVehicleModelList().subscribe(res=>{
      console.log(res)
      if(res['status'] && res['status'] == 'SUCCESS'){
        res['response']['MANUFACTURERS'].forEach(element => {
          let model = {label: element, value : element}
          this.vehicleManufacturerOption.push(model)
        });
        this.getModel(this.vehicleManufacturerOption[0].value)
      }
    })
    this.fleetId = localStorage.getItem('fleetId')
    this.getVehicleData()
  }

  getVehicleData() {
    this._vehicleService
      .getVehicles(this.fleetId)
      .subscribe((res) => {
        console.log(res)
        if (res['status'] && res['status'] == 'SUCCESS') {
          this.vehicleData = []
          res['response'].forEach((element) => {
            let vehicle = {
              id: element['id'],
              vehicleNumber : element['vehicleNumber'],
              manufacturer : element['manufacturer'] ? element['manufacturer'] : '-',
              model : element['model'],
              variant : element['variant'] ? element['variant'] : '-' ,
              kmRange : element['kmRange'] ? element['kmRange'] : '-',
              chargerType : element['chargerType']
            }
            this.vehicleData.push(vehicle)
          })
        }
      })
  }
  openUpdateVehicleDetails(vehicle) {
    this.updateDetailsDialog = true
    if (vehicle) {
      this.getModel(vehicle.manufacturer)
      this.updateVehicleDto = {
        id: vehicle.id,
        vehicleNumber: vehicle.vehicleNumber,
        model: vehicle.model+"  "+vehicle.variant,
        manufacturer: vehicle.manufacturer,
        fleetId: Number(this.fleetId),
        apiSource: 'FLEET_DASHBOARD'
      }
      this.action = 'Save'
    } else {
      this.getModel(this.vehicleManufacturerOption[0].value)
      this.updateVehicleDto = {
        id: null,
        vehicleNumber: null,
        model: this.vehicleModelOption[0].value,
        manufacturer: this.vehicleManufacturerOption[0].value,
        fleetId: Number(this.fleetId),
        apiSource: 'FLEET_DASHBOARD'
      }
      this.showVarientDropDown=true
      this.action = 'Add Vehicle'
    }
  }
  updateVehicle() {
    console.log(this.updateVehicleDto)
    if (
      this.updateVehicleDto.vehicleNumber == null ||
      this.updateVehicleDto.model == null ||
      this.updateVehicleDto.manufacturer == null
    ) {
      Swal.fire('Required!', 'Registration Number, car model and manufacturer are required.', 'error')
      return
    }
    if (this.updateVehicleDto.vehicleNumber.toString().length != 10) {
      Swal.fire('Required!', 'Enter valid vehicle No.', 'error')
      return
    }
    if (this.action == 'Save')
      this._vehicleService
        .updateVehicle(this.updateVehicleDto)
        .subscribe((res) => {
          if (res['status'] && res['status'] == 'SUCCESS') {
            Swal.fire({
              title: 'Success',
              icon: 'success',
              text: 'Sucessfully Updated Vehicle',
            })
            this.updateDetailsDialog = false
            this.getVehicleData()
          } else if(res['errorCode'] && res['errorCode'] == 400){
            Swal.fire({
              title: 'Oops!',
              icon: 'error',
              text: 'Error : '+res['errorMessage'],
            })
          } else{
            Swal.fire({
              title: 'Oops!',
              icon: 'error',
              text: 'Something went wrong!'
            })
          }
        })
    else{
      let addVehicle = {
        vehicleNumber:  this.updateVehicleDto.vehicleNumber,
        model: this.updateVehicleDto.model,
        manufacturer:  this.updateVehicleDto.manufacturer,
        fleetId: Number(this.fleetId),
        apiSource: 'FLEET_DASHBOARD'
      }
      this._vehicleService.addVehicle(addVehicle).subscribe(
        (res) => {
          if (res['status'] && res['status'] == 'SUCCESS') {
            Swal.fire({
              title: 'Success',
              icon: 'success',
              text: 'Vehicle Added',
            })
            this.updateDetailsDialog = false
            this.getVehicleData()
          } else if(res['errorCode'] && res['errorCode'] == 400){
            Swal.fire({
              title: 'Oops!',
              icon: 'error',
              text: 'Error : '+res['errorMessage'],
            })
          } else{
            Swal.fire({
              title: 'Oops!',
              icon: 'error',
              text: 'Something went wrong!'
            })
          }
        },
        (err) => {
          console.log(err)
          Swal.fire({
            title: 'Oops!',
            icon: 'error',
            text: err.statusText,
          })
        },
      )}
  }
  deleteVehicle(vehicle) {
    Swal.fire({
      title: 'Warning!',
      html:
        `Are you sure you want to remove ` + vehicle.vehicleNumber + ` from the list?`,
      icon: 'warning',
      cancelButtonText: 'No, keep it',
      confirmButtonText: 'Yes, remove',
      confirmButtonColor: '#2C66E3',
      showCancelButton: true,
    }).then((result) => {
      if (result.value == true) {
        this._vehicleService.deleteVehicle(vehicle.vehicleNumber).subscribe(
          (res) => {
            console.log(res)
            if (res['status'] == 'SUCCESS')
              Swal.fire({
                title: 'Success',
                icon: 'success',
                text: 'Sucessfully Deleted',
              })
            this.getVehicleData()
          },
          (err) => {
            console.log(err)
            Swal.fire({
              title: 'Oops!',
              icon: 'error',
              text: err.statusText,
            })
          },
        )
      }
    })
  }
  getModel(manufacturer){

    this._vehicleService.getVehicleModelList(manufacturer).subscribe(res=>{
      console.log(res)
      if(res['status'] && res['status'] == 'SUCCESS'){
        this.vehicleModelOption=[]
        res['response']['MODELS'].forEach(element => {
          let model = {label: element, value : element}
          this.vehicleModelOption.push(model)
        });
        this.updateVehicleDto.model = this.vehicleModelOption[0].value
        this.showVarientDropDown= true;
        console.log(this.vehicleModelOption)
      }
    })

  }
  downloadCSV(){
    // 1.CSV_FOR_PAST_TRANSACTIONS,
    // 2.CSV_FOR_RESERVATIONS,
    // 3.CSV_FOR_VEHICLES,
    // 4.CSV_FOR_DRIVERS
    let csvType ="CSV_FOR_VEHICLES";
     this._appService.downloadCSV(csvType,this.fleetId,null,null).subscribe(data => 
      saveAs(data, `vehicleData.csv`),
       err => { }
     )
   }
}

import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { SelectItem } from 'primeng/api';
import { PaymentService} from '../../../services/payment/payment.service';
import * as moment from 'moment';
import { AppService } from 'src/app/services/app.service';
import { RAZORPAY_INITIATE } from 'src/app/config';
import { NgxSpinnerService } from "ngx-spinner";
import { saveAs } from 'file-saver';
import { getDuration } from '../../../util/index';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.css']
})
export class PaymentComponent implements OnInit {


  constructor(private _paymentService: PaymentService, private _appService: AppService,private spinner: NgxSpinnerService) {}
  paymentData = []
  fleetId
  showAddMoney = false
  selectedDates = [moment().startOf('d').toDate(), moment().startOf('d').add(1,'d').toDate()];
  start = moment().startOf('d').toDate().getTime()
  end= moment().startOf('d').add(1,'d').toDate().getTime()
  amount
  currentAmount: Number
  orderId
  newWindow:Window;
  interval: any
  cols = [
    { field: 'bookingDetails', header: 'Booking Details', isVisible: true ,width : '130px'},
    { field: 'transactionId', header: 'Transaction ID', isVisible: true },
    { field: 'reservationId', header: 'Reservation ID', isVisible: true },
    { field: 'dateAndTime', header: 'Date & Time', isVisible: true ,width : '200px'},
    { field: 'chargingDetails', header: 'Charger Details', isVisible: true ,width : '200px'},
    { field: 'chargingDuration', header: 'Charging Time', isVisible: true },
    { field: 'paymentOrderType', header: 'Order Type', isVisible: true ,width : '100px'},
    { field: 'amt', header: 'Amount', isVisible: true },
    { field: 'closingBalance', header: 'Closing Balance', isVisible: true },
    { field: 'action', header: 'Action', isVisible: true },
    { field: 'id', header: 'Id', isVisible: false },
    { field: 'vehicleNumber', header: 'vehicle', isVisible: false },
    { field: 'station', header: 'station', isVisible: false },
    { field: 'charger', header: 'charger', isVisible: false },
  ]
  ngOnInit(): void {
    this.getPaymentData();
  }

  showAddMoneyDialog(){
    this.showAddMoney = true
    this.amount =null
     this._appService.getWelletBalance(this.fleetId).subscribe(res=>{
       if(res['status'] && res['status']=='SUCCESS')
         {this._appService.setWalletBalance(res['response'])
          this.currentAmount = Number(res['response'])
        }
     })

  }

  updateAmount(){
    let body ={
      "amount": this.amount,
      "fleetId": this.fleetId,
      "userSsoId": localStorage.getItem('ssoId')
     }
  this._paymentService.addAmount(body).subscribe(res=>{
    console.log(res)
    if(res['status'] && res['status']=='SUCCESS')
     { this.orderId = res['response']['orderId']
      this.newWindow = window.open(RAZORPAY_INITIATE+res['response']['orderId']+'?phoneNo='+res['response']['phone'] +'&email='+res['response']['email'],"Add Money","toolbar=no,scrollbars=no,location=no,statusbar=no,menubar=no,resizable=0,width=1000,height=600,left = 200,top = 100"); 
      this.spinner.show()
      this.keepEye(this.newWindow);
     }
  })
  }

  getPaymentData() {
    this.fleetId = localStorage.getItem('fleetId')  
    this._paymentService.getPaymentList(this.fleetId,this.selectedDates[0].getTime(),this.selectedDates[1].getTime())
      .subscribe((res) => {
        console.log(res)
        if (res['status'] && res['status'] == 'SUCCESS') {
          this.paymentData = []
          res['response'].forEach((element) => {
            let payment = {
              bookingDetails : element['bookingDetails'], 
              dateAndTime : element['dateAndTime'], 
              chargingDetails : element['chargingDetails'], 
              chargingDuration : element['chargingDuration'],
              chargingTimeInMillis : element['chargingTimeInMillis'],
              paymentOrderType : element['walletTxnOrderType'], 
              amt : element['amountDetails']['amt'], 
              transactionId : element['transactionId'] == null ? '-' : element['transactionId'],
              reservationId: element['reservationId'] == null ? '-' : element['reservationId'],
              closingBalance : element['amountDetails']['closingBalance'], 
              id: element['bookingDetails']['id'],
              vehicleNumber: element['bookingDetails']['vehicleNumber'],
              station: element['chargingDetails'] != null ? element['chargingDetails']['chargingStation'] : null,
              charger: element['chargingDetails'] != null ? element['chargingDetails']['chargerName'] : null,
              isToDownloadReceipt: element['isDownloadReceiptEligible']
            }
            this.paymentData.push(payment)
          })
        }
      })
  }

  keepEye(window:Window){
  
    this.interval = setInterval(()=> {
       if(window.closed){
        console.log("closed");
         this._paymentService.checkStatus(this.orderId).subscribe((response:any)=>{
           if(response.status>=300){
            Swal.fire({
              title: 'Hmm...',
              icon: 'info',
              text: response.message
            });
            clearInterval(this.interval); 
            this.spinner.hide()
           }
           if(response.response){
             let message= "₹"+this.amount + " has been added to your business wallet."
            Swal.fire({
              title: '<span style="color:#5DCF5E">Payment Successful</span>',
              imageUrl: '../../../../assets/addMoneySuccess.png',
              text: message
            });
            this.showAddMoney = false
            this._appService.getWelletBalance(this.fleetId).subscribe(res=>{
              if(res['status'] && res['status']=='SUCCESS')
                {this._appService.setWalletBalance(res['response'])
                 this.currentAmount = Number(res['response'])
               }
            })
            clearInterval(this.interval); 
            this.getPaymentData();
            this.spinner.hide()
           }else {
            Swal.fire({
              title: 'Hmm...',
              icon: 'info',
              text: "Transaction is in Status: "+ response.response
            });
            clearInterval(this.interval); 
            this.spinner.hide()
           }
         })
         
       }
  },1000);
    
   }
   downloadCSV(){
    let csvType= "CSV_FOR_WALLET"
     this._appService.downloadCSV(csvType,this.fleetId,this.selectedDates[0].getTime(),this.selectedDates[1].getTime()).subscribe(data => 
      saveAs(data, `walletData.csv`),
       err => { }
     )
   }
   downloadReceipt(id){
    let orderIdArr = id.split('-')
      this._appService.downloadReceipt(orderIdArr[1]).subscribe(data => 
        saveAs(data, `receipt.pdf`),
         err => { }
       )
   }
   getOrderTypeClass(orderType){
    console.log("getOrderType",orderType)
     switch(orderType){
       case 'PAID' : return 'paid-order'
       case 'REFUND' : return 'refund-order';
       case 'ADDED' : return 'added-order';
       case 'PENDING': return 'pending-order';
       case 'FAILED': return 'failed-order';
       case 'BLOCKED': return 'blocked-order';
       case 'RELEASED': return 'released-order'; 
     }
     return;
   }
   getAnalogyOrder(orderType){
    switch(orderType){
      case 'PAID' : return 'Paid'
      case 'REFUND' : return 'Refund';
      case 'ADDED' : return 'Added';
      case 'PENDING': return 'Pending';
      case 'FAILED': return 'Failed';
      case 'BLOCKED': return 'Blocked';
      case 'RELEASED': return 'Released'; 
    }
    return;
   }
}

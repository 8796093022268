import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LOGIN,LOGOUT,CHECK_TOKEN,GET_USER_ROLE,GET_WALLET_BALANCE,GET_FAQ_LIST, DOWNLOAD_CSV,DOWNLOAD_RECEIPT_FOR_ORDER_ID } from './../config/index';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  
  isValid: Boolean = false;
  name: String ="";
  walletBalance:any;

  private walletBalanceNoti = new BehaviorSubject([]);
  walletBalanceMessage = this.walletBalanceNoti.asObservable();

  private userNameNoti = new BehaviorSubject([]);
  userName = this.userNameNoti.asObservable();
  
  
  constructor(private _http: HttpClient) { }
  login(data){
     return this._http.post(LOGIN, data);
  }
  logout(){
    return this._http.post(LOGOUT,{});
  }
  checkToken(){
    return this._http.get(CHECK_TOKEN);
  }
  getRolesForUser(ssoId){
     return this._http.get(GET_USER_ROLE+ssoId)
  }
  getWelletBalance(fleetId){
    return this._http.get(GET_WALLET_BALANCE+fleetId);
  }
  setWalletBalance(balance){
    this.walletBalance = balance;
    this.walletBalanceNoti.next(balance);
  }
  getWalletBalance(){
    return this.walletBalance;
  }
  setUserName(name){
    this.name = name;
    this.userNameNoti.next(name);
  }
  getUserName(){
     return this.name;
  }
  getFaq(){
    return this._http.get(GET_FAQ_LIST);
  }
  downloadCSV(csvType,fleetId,start,end): Observable<Blob> {
    let url = csvType+"/"+fleetId
    if(start && end )
      url =  url+"?startTime="+start+"&endTime="+end
    return this._http.get(DOWNLOAD_CSV+url,
    { responseType: 'blob' })
  }
  downloadReceipt(orderId): Observable<Blob> {  
    return this._http.get(DOWNLOAD_RECEIPT_FOR_ORDER_ID+orderId,{responseType: 'blob'})
  }
}

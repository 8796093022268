import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GET_VEHICLE_DATA,UPDATE_VEHICLE_DATA ,GET_VEHICLE_MODEL_LIST, ADD_VEHICLE, DELETE_VEHICLE} from '../../config/index';

@Injectable({
  providedIn: 'root'
})
export class VehicleService {
  constructor(private _http: HttpClient) { }

  getVehicles(fleetId){
    return this._http.get(GET_VEHICLE_DATA+fleetId);
  }
  updateVehicle(vehicle){
    return this._http.put(UPDATE_VEHICLE_DATA,vehicle);
  }
  addVehicle(vehicle){
    return this._http.post(ADD_VEHICLE,vehicle);
  }
  deleteVehicle(vehicleNumber){
    return this._http.delete(DELETE_VEHICLE+vehicleNumber,{})
  }
  getVehicleModelList(manufacturer?){
    if(manufacturer)
     return this._http.get(GET_VEHICLE_MODEL_LIST+'?manufacturer='+manufacturer)
    return this._http.get(GET_VEHICLE_MODEL_LIST);
  }
}

import { Component, OnInit } from '@angular/core'
import { DriverService } from '../../../../services/driver/driver.service'
import Swal from 'sweetalert2'
import { SelectItem } from 'primeng/api'
import { AppService } from 'src/app/services/app.service';
import { saveAs } from "file-saver";

@Component({
  selector: 'app-driver',
  templateUrl: './driver.component.html',
  styleUrls: ['./driver.component.css'],
})
export class DriverComponent implements OnInit {
  constructor(private _driverService: DriverService , private _appService: AppService) {}
  driverData = []
  offSet = 0
  limit = 10
  action
  fleetId
  updateDetailsDialog = false
  updateDriverDto = {
    userPk: null,
    name: '',
    email: '',
    phone: null,
    sex:'MALE',
    fleetId: null,
    isApiSourceFleet: true
  }
  sexOption:  SelectItem[];
  cols = [
    { field: 'userId', header: 'User ID', isVisible: true },
    { field: 'name', header: 'Name', isVisible: true },
    { field: 'email', header: 'Email', isVisible: true },
    { field: 'phone', header: 'Phone', isVisible: true },
    { field: 'action', header: 'Action', isVisible: true },
  ]
  ngOnInit(): void {
    this.sexOption = [{label: 'Female', value: 'FEMALE'},
    {label: 'Male', value: 'MALE'},
    {label: 'Other', value: 'OTHER'}]
    this.getDriverData()
  }
  getDriverData() {
    this.fleetId = localStorage.getItem('fleetId')
    this._driverService
      .getDrivers(this.fleetId)
      .subscribe((res) => {
        console.log(res)
        if (res['status'] && res['status'] == 'SUCCESS') {
          this.driverData = []
          res['response'].forEach((element) => {
            let driver = {
              userId: element['userPk'],
              name: element['name'],
              email: element['email'],
              phone: element['phone'],
              sex: element['sex']
            }
            this.driverData.push(driver)
          })
        }
      })
  }
  openUpdateDriverDetails(driver) {
    this.updateDetailsDialog = true
    if (driver) {
      this.updateDriverDto = {
        userPk: driver.userId,
        name: driver.name,
        email: driver.email,
        phone: driver.phone,
        sex:driver.sex ? driver.sex : 'MALE',
        fleetId: Number(this.fleetId),
        isApiSourceFleet: true
      }
      this.action = 'Save'
    } else {
      this.updateDriverDto = {
        userPk: null,
        name: '',
        email: '',
        phone: null,
        sex:'MALE',
        fleetId: Number(this.fleetId),
        isApiSourceFleet: true
      }
      this.action = 'Add Driver'
    }
  }
  updateDriver() {
    if (
      this.updateDriverDto.name == '' ||
      this.updateDriverDto.email == '' ||
      this.updateDriverDto.phone == null
    ) {
      Swal.fire('Required!', 'Name, email and phone are required.', 'error')
      return
    }
    if (this.updateDriverDto.phone.toString().length != 10) {
      Swal.fire('Required!', 'Enter valid Phone No.', 'error')
      return
    }
    if (this.action == 'Save')
      this._driverService
        .updateDriver(this.updateDriverDto)
        .subscribe((res) => {
          if (res['status'] && res['status'] == 'SUCCESS') {
            Swal.fire({
              title: 'Success',
              icon: 'success',
              text: 'Sucessfully Updated Driver',
            })
            this.updateDetailsDialog = false
            this.getDriverData()
          } else if (res['errorCode'] && res['errorCode'] == 400) {
            Swal.fire({
              title: 'Oops!',
              icon: 'error',
              text: 'Error : '+res['errorMessage'],
            })
          }else {
            Swal.fire({
              title: 'Oops!',
              icon: 'error',
              text: 'Something went wrong',
            })
          }
        })
    else{
      let addDriver= {
        name: this.updateDriverDto.name,
        email: this.updateDriverDto.email,
        phone: this.updateDriverDto.phone,
        sex:this.updateDriverDto.sex,
        fleetId: Number(this.fleetId),
        isApiSourceFleet: true
      }
      this._driverService.addDriver(addDriver).subscribe(
        (res) => {
          if (res['status'] && res['status'] == 'SUCCESS') {
            Swal.fire({
              title: 'Success',
              icon: 'success',
              text: 'Sucessfully Added Driver',
            })
            this.updateDetailsDialog = false
            this.getDriverData()
          } else if (res['errorCode'] && res['errorCode'] == 400){
            Swal.fire({
              title: 'Oops!',
              icon: 'error',
              text: 'Error : '+res['errorMessage'],
            })
          }else {
            Swal.fire({
              title: 'Oops!',
              icon: 'error',
              text: 'Something went wrong',
            })
          }
        },
        (err) => {
          console.log(err)
          Swal.fire({
            title: 'Oops!',
            icon: 'error',
            text: err.statusText,
          })
        },
      )}
  }
  deleteDriver(driver) {
    Swal.fire({
      title: 'Warning!',
      html:
        `Are you sure you want to remove ` + driver.name + ` from the list?`,
      icon: 'warning',
      cancelButtonText: 'No, keep it',
      confirmButtonText: 'Yes, remove',
      confirmButtonColor: '#2C66E3',
      showCancelButton: true,
    }).then((result) => {
      if (result.value == true) {
        this._driverService.deleteDriver(driver.userId).subscribe(
          (res) => {
            console.log(res)
            if (res['status'] == 'SUCCESS')
              Swal.fire({
                title: 'Success',
                icon: 'success',
                text: 'Successfully Deleted',
              })
            this.getDriverData()
          },
          (err) => {
            console.log(err)
            Swal.fire({
              title: 'Oops!',
              icon: 'error',
              text: err.statusText,
            })
          },
        )
      }
    })
  }
  downloadCSV(){
    let csvType ="CSV_FOR_DRIVERS";
     this._appService.downloadCSV(csvType,this.fleetId,null,null).subscribe(data => 
      saveAs(data, `driverData.csv`),
       err => { }
     )
   }
}

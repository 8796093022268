import { AppService } from './services/app.service';
import Swal from 'sweetalert2';
import { Injectable } from "@angular/core";
import { tap } from "rxjs/operators";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse,
  HttpHeaders
} from "@angular/common/http";
import { Observable } from "rxjs";
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { COOKIE_URL } from './config/index';
@Injectable()
export class AppInterceptor implements HttpInterceptor {
  constructor(private _cookieService: CookieService, private _appService: AppService, private _router: Router) {
  }
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token = this._cookieService.get('accessToken');
    let updatedRequest;

    const header: any = {};
    for (const headerKey of request.headers.keys()) {
      header[headerKey] = request.headers.get(headerKey);
    }

    if (token) {
      header.Authorization = `Bearer ${token}`;
      //header.businessAccountId = localStorage.getItem('businessAccountId');
      updatedRequest = request.clone({
        headers: new HttpHeaders(header)
      });

    } else {
      updatedRequest = request.clone({
        headers: new HttpHeaders()
      });
    }

    return next.handle(updatedRequest).pipe(
      tap(
        event => {
          if (event instanceof HttpResponse) {
            if (event.body.statusCode == 401) {
              this._cookieService.delete('accessToken', '/',COOKIE_URL);
              this._router.navigateByUrl('/login');
              this._appService.isValid = false;
            }
            else {
            }
          }
        },
        error => {
          if (error instanceof HttpErrorResponse) {
          }
        }
      )
    );
  }
}

<div class="container">
  <div class="table-container">
    <p-table #dt [columns]="cols" [value]="vehicleData" sortMode="multiple"
    [paginator]="true" [rows]="10" totalRecords="10000" >
    <ng-template pTemplate="caption" class="header">
      
      <div style="display: flex; justify-content: space-between;">
        <input  class="form-control" type="text" style="width: 250px;" pInputText size="50" placeholder="Search"
        (input)="dt.filterGlobal($event.target.value, 'contains')" lazy="false" />
        <div style="text-align: center;">
          <button class="btn btn-primary" (click)="openUpdateVehicleDetails(null)"> Add Vehicle</button>
          <button class="btn btn-basic" (click)="downloadCSV()"><i class="fas fa-download"></i></button>
       </div>
    </div>
    
    </ng-template>
          <ng-template pTemplate="header" let-columns>
            <tr>
              <ng-container *ngFor="let col of columns">
                <th *ngIf="col.isVisible" [ngStyle]="{ width: col.width }">
                  <ng-container [ngSwitch]="col.field">
                    {{ col.header }}
                    <ng-container *ngSwitchCase="'paymentOrderStatus'">
                      <br />
                      <p-multiSelect
                        [options]="orderStatusType"
                        defaultLabel="All"
                        (onChange)="dt.filter($event.value, col.field, 'in')"
                        [style]="{ width: '90px', 'margin-top': '5px' }"
                        [panelStyle]="{ width: '170px' }"
                      ></p-multiSelect>
                    </ng-container>
                  </ng-container>
                </th>
              </ng-container>
            </tr>
            <!-- <tr *ngIf="displayNone && loading == false" >
              <td colspan="11" style="font-size: 13px !important; padding: 10% !important; text-align: center;">
                  No data found
              </td>
          </tr> -->
            <!-- <div *ngIf="loading == true"
                style="display: flex;width:100%;height:100%;margin-left: 46em; margin-top: 3em;margin-bottom: 3em;">
                <p-progressSpinner [style]="{width: '50px', height: '50px',margin:'auto'}" strokeWidth="8" fill="#323232"
                  animationDuration=".5s"></p-progressSpinner>
              </div> -->
          </ng-template>
          <ng-template
            pTemplate="body"
            let-vehicleData
            let-columns="columns"
            let-i="rowIndex"
          >
            <tr [pSelectableRow]="vehicleData">
              <ng-container *ngFor="let col of columns">
                <td *ngIf="col.isVisible">
                  <ng-container [ngSwitch]="col.field">

                    <ng-container *ngSwitchCase="'action'">
                        <td>
                          <a style="cursor: pointer;" (click)="openUpdateVehicleDetails(vehicleData)"> <i class="fas fa-edit"></i></a>
                          <a style="cursor: pointer;" (click)="deleteVehicle(vehicleData)"> <i class="fas fa-trash-alt"></i></a>
                        </td>
                      </ng-container>
                    
                    <ng-container *ngSwitchCase="'chargerType'">
                        <img style="padding: 4px;" src="../../../../assets/fastCharger.png">
                        <img style="padding: 4px;" src="../../../../assets/slowCharger.png">
                    </ng-container>
                    <ng-container *ngSwitchDefault>
                      {{ vehicleData[col.field] }}
                    </ng-container>
                  </ng-container>
                </td>
              </ng-container>
            </tr>
          </ng-template>
        </p-table>
      </div>      
</div>
<p-dialog [(visible)]="updateDetailsDialog" header="Vehicle Details" [style]="{width: '50vw'}">
  <div class="vehicle-container">
      <div class="input-group mb-3" class="row">
          <span class="ui-float-label" style="width: 100%;">
          <label for="username" style="color: #2C66E3;font-weight: 500; margin:0px;font-size:14px">Registration Number</label>
          <input pInputText type="text" class="form-control  customFormInput" style="padding-left: 15px;" id="vehicleNumber"  [(ngModel)]="updateVehicleDto.vehicleNumber" >
          </span>
      </div>
      <br/>
      <div class="input-group mb-3" class="row">
           <span class="ui-float-label" style="width: 100%;">
           <label for="password" style="color: #2C66E3;font-weight: 500;margin:0px;font-size:14px">Car Manufacturer</label><br/>
           <p-dropdown appendTo="body" class="customFormInput" [options]="vehicleManufacturerOption" [(ngModel)]="updateVehicleDto.manufacturer" (onChange)="getModel(updateVehicleDto.manufacturer)"></p-dropdown>
           </span>
      </div>
      <br/>
      <div *ngIf="showVarientDropDown" class="input-group mb-3" class="row">
        <span class="ui-float-label" style="width: 100%;">
        <label for="password" style="color: #2C66E3;font-weight: 500;margin:0px;font-size:14px">Car Model</label><br/>
        <p-dropdown appendTo="body" class="customFormInput" [options]="vehicleModelOption" [(ngModel)]="updateVehicleDto.model" (onChange)="selectModel()" ></p-dropdown>
        </span>
       </div>

      <br/>
      <div class="dialog-buttons">
          <button class="btn btn-basic" (click)="updateDetailsDialog = false" > Cancel</button>
          <button class="btn btn-primary" (click)="updateVehicle()" style="margin-left: 10px;"> {{action}}</button>       
      </div>

  </div>
</p-dialog>
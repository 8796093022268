import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GET_DRIVER_DATA,UPDATE_DRIVER_DATA,ADD_DRIVER ,DELETE_DRIVER} from '../../config/index';

@Injectable({
  providedIn: 'root'
})
export class DriverService {

  constructor(private _http: HttpClient) { }

  getDrivers(fleetId){
    return this._http.get(GET_DRIVER_DATA+fleetId);
  }
  updateDriver(driver){
    return this._http.put(UPDATE_DRIVER_DATA,driver);
  }
  addDriver(driver){
    return this._http.post(ADD_DRIVER,driver);
  }
  deleteDriver(driverId){
    return this._http.post(DELETE_DRIVER+driverId+"?source=FLEET_DASHBOARD",{})
  }
}

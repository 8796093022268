<div class="container-fluid header-nav sticky-top" >
    <!-- <div class="row justify-content-space-between"> -->
    <div id="mySidenav" class="sidenav" [ngStyle]="{'width':collapse==false?'60px':'225px'}" style="top:0px" >
      <!-- <a class="closebtn close" (click)="closeNav()">&times;</a> -->
  
      <div class="container" style="overflow: hidden;">
        <div class="row" style="width: 500px;">
          <div class="col-6" style="height: 50px;">
            <!-- <span class="tile">Symphony</span> -->
            
            <span style="font-size:15px;cursor:pointer;color: white;"   (click)="openNav()"
            > <img src="../../../../assets/hamburger.png" style="height: 20px;margin-top: 7px;position: fixed;"> </span>
            &nbsp;&nbsp;
            <span *ngIf="collapse==true">
              <img src="../../../../assets/BlusmartWhite.png" alt="" width='70' style="margin-left: 25px;margin-top: 5px;">
            </span>
            
          </div>
  
        </div>
        
        
      </div>
     
      <div class="list-group" id="list-tab" role="tablist" >
  
          <a  class="list-group-item list-group-item-action" *ngIf="checkPermission('MENU_CHARGING_LOCATION')"
          id="list-user-list" data-toggle="list" role="tab" aria-controls="charging-location" [routerLink]="['/charging-location']"
          routerLinkActive="active" ><i class="fas fa-charging-station"></i><span *ngIf="collapse==true" style="margin-left: 10px;">Charging Locations </span></a>
  
          <a class="list-group-item list-group-item-action" *ngIf="checkPermission('MENU_CHARGING_TRANSACTION')"
          id="list-home-list" data-toggle="list" role="tab" aria-controls="charging-transactions" [routerLink]="['/charging-transactions']"
          routerLinkActive="active" ><i class="fas fa-bolt"></i><span *ngIf="collapse==true" style="margin-left: 10px;">Charging Transaction </span></a>
  
          <a class="list-group-item list-group-item-action" *ngIf="checkPermission('MENU_PAYMENTS')"
          id="list-settings-list" data-toggle="list" role="tab" aria-controls="payments"
          [routerLink]="['/payments']" routerLinkActive="active" ><i class="fas fa-money-check"></i><span *ngIf="collapse==true" style="margin-left: 10px;">Payments </span></a>

          <!-- <a class="list-group-item list-group-item-action" *ngIf="checkPermission('MENU_PROFILE')"
          id="list-profile-list" data-toggle="list" role="tab" aria-controls="profile"
          [routerLink]="['/profile']" routerLinkActive="active" ><i class="fas fa-user-circle"></i><span *ngIf="collapse==true" style="margin-left: 10px;">Profile</span></a> -->
  
          <a class="list-group-item list-group-item-action" *ngIf="checkPermission('MENU_DRIVERS')"
          id="list-profile-list" data-toggle="list" role="tab" aria-controls="drivers"
          [routerLink]="['/drivers']" routerLinkActive="active" ><i class="fas fa-users"></i><span *ngIf="collapse==true" style="margin-left: 10px;">Drivers</span></a>

          <a class="list-group-item list-group-item-action" *ngIf="checkPermission('MENU_VEHICLE')"
          id="list-profile-list" data-toggle="list" role="tab" aria-controls="vehicle"
          [routerLink]="['/vehicle']" routerLinkActive="active" ><i class="fas fa-car"></i><span *ngIf="collapse==true" style="margin-left: 10px;">Vehicle</span></a>

          <a class="list-group-item list-group-item-action" *ngIf="checkPermission('MENU_HELP')"
          id="list-profile-list" data-toggle="list" role="tab" aria-controls="help"
          [routerLink]="['/help']" routerLinkActive="active" ><i class="fas fa-question-circle"></i><span *ngIf="collapse==true" style="margin-left: 10px;">Help</span></a>
      </div>
  
    </div>
  
    <div class='d-flex justify-content-between'>
      <div class='d-flex justify-content-around'>
  
        <span style="font-size:15px;cursor:pointer;color: white;" class="col-xs-4 ml-3 mb-1" (click)="openNav()"
          > <img src="../../../../assets/hamburger.png" style="height: 20px;"> </span>
        <div class='ml-3'>
        </div>
  
      </div>
  
      <div style="margin-top: auto; margin-bottom: auto;display: flex;flex-direction: row;">
        <div style="display: flex;flex-direction: column;text-align: right;margin-top: auto; margin-bottom: auto;" *ngIf="walletBalance != undefined">
          <sup style="color:#334D6E;margin-top: 10px;font-size: 12px;">Total Wallet Balance</sup>
          <div style="color: #3E4958; font-weight: 600;">₹ {{walletBalance | number}}</div>
        </div>
        <div *ngIf="name" class="name">Hello, {{name}}</div>
        <i class="fa fa-sign-out" aria-hidden="true" style="margin-right: 5px;margin-top: 5px;color: #111827;"></i> <a class='logout' (click)='logout()' (click)='closeNav()' style="color: #111827;">Logout</a>
      </div>
      
    </div>
  
  </div>